(function () {
  'use strict';

  // Insert plausible analytics.
  const script = document.createElement('script');
  script.dataset.domain = location.hostname;
  script.defer = true;
  script.src = '/js/script.js';
  document.head.append(script);
})();
